import React, { Component } from "react";
import "./style.css";
// import getConfig from 'next/config';
// const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: true,
      data: {},
      csHTML: {
        day: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
    };
  }

  showAnimatedBox = () => {
    this.setState({ showNotification: !this.state.showNotification });
  };

  componentDidMount = () => {};

  render() {
    let { showNotification } = this.state;
    return (
      <div className="header-ddr">
        {!showNotification && (
          <div className="custome-arr-box" onClick={this.showAnimatedBox}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="43.6 92.5 315 315"
            >
              <path d="M49.6 92.5c-3.3 0-6 2.7-6 6v303c0 3.3 2.7 6 6 6h303c3.3 0 6-2.7 6-6v-303c0-3.3-2.7-6-6-6H49.6zM229.6 254.3c-3.3 0-6 2.7-6 6V360c0 3.3-2.7 6-6 6h-33c-3.3 0-6-2.7-6-6v-99.7c0-3.3-2.7-6-6-6H99.2c-3.3 0-4.2-2-2-4.5l99.9-111.4c2.2-2.5 5.8-2.5 8 0l99.9 111.4c2.2 2.5 1.3 4.5-2 4.5H229.6z"></path>
            </svg>
          </div>
        )}
        {showNotification && (
          <div className="nt-hh">
            <div className="nt-main">
              <div className="icon-close" onClick={this.showAnimatedBox}>
                <img src="../close.png" alt="close" />
              </div>
              <div className="text-center cdfg-text notificationtext">
                <p>
                  All orders placed after October 21st 2022, will be processed
                  after October 25th 2022
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
