import React, { Component } from "react";

import { Row, Col ,Container} from "react-bootstrap";



import DataTable from '../../components/data-table';
import SERVICES from "../../services/apicalls";
import Layout from "../layout";
import './style.css'

import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderDetails: null,
            // trackOrder : this.props.trackOrder,
            trackOrder:"",
            skeleton_load : true
        };
      }
    
    async componentDidMount(){
        let order_id = this.props.match.params.order_id
        try {
            let orderDetails = await SERVICES._orderDetails({order_id : order_id})
            if(orderDetails.result.status){
                orderDetails = orderDetails.result.orderdetails
                this.setState({
                    orderDetails : orderDetails,
                    skeleton_load : false
                })
                if(orderDetails && orderDetails.waybill_number){
                    let trackOrder = await SERVICES._blueDartTracking(`${orderDetails.waybill_number}`)
                    console.log(trackOrder,"trackOrder")
                    trackOrder = JSON.parse(JSON.stringify(trackOrder))
                    if(trackOrder.Status){
                        console.log("in if");
                        
                        this.setState({trackOrder:trackOrder.data})
                    }
                }
            }else{
                this.setState({
                    orderDetails : null,
                    skeleton_load : false
                })
            }
        } catch (error) {
            this.setState({
                skeleton_load : false
            })
            console.log(error);
        }
    }
   
  render(){
    let {orderDetails, trackOrder, skeleton_load} = this.state
    console.log("trackOrder",trackOrder)
      return(
        <div>
            <Layout allProps={this.props}></Layout>
            
            <Container>
                {skeleton_load?<section className="orders-sec mt-5">
                    <SkeletonTheme color="#ffff">
                        <p>
                            <Skeleton count={5} />
                        </p>
                    </SkeletonTheme>
                </section>:
                <section className="orders-sec mt-5">
                    {orderDetails?<Row>
                        <Col>
                            <div className="ur-orders">
                                    order details</div>
                                    <div className="or-row">
                                        <div className="row-box mb-3 mt-3">
                                            <Row>
                                                <Col md={8}>
                                                    <div>
                                                        <div className="order-id">order id: {orderDetails.id}</div>
                                                        
                                                        <div className="order-count mt-2">order placed on: <strong>{new Date(orderDetails.created_at).toDateString()}</strong></div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="custom-add text-right">
                                                        <span variant="primary" className="text-uppercase">{orderDetails.status_name}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="order-count mt-2 bluedart-text">Note: In case the tracking updates are not showing, please use your Tracking/ Waybill no: <strong>{orderDetails.waybill_number}</strong> and check your order status <a className="dart-link" href="https://www.bluedart.com/how-to-track" target='_blank'>here.</a></div>
                                    {trackOrder?
                                    <div>
                                        <div className="order-id mt-5 mb-10"><strong>BlueDart tracking details</strong></div>
                                        <DataTable sessions={trackOrder.Shipment.Scans.ScanDetail} />
                                    </div>:
                                    <div>
                                        <div className="order-id mt-5 mb-10"><strong>BlueDart tracking details<span className="logo-clr">&nbsp;(Tracking details will be updated shortly)</span></strong></div>
                                        <DataTable sessions={[]} />
                                    </div>
                                    }
                        </Col>
                    </Row>:
                    <div className="text-center">
                        <div className="mb-3">
                            <img src="/web-error.svg" alt="Almondhouse"></img>
                        </div>
                        <h1>No order found</h1>
                    </div>}
                </section>}
            </Container>
            
        </div>
      )
  }
}
