import React, { useState } from "react";
import { message } from "antd";
import { StateData } from "../../jsonData";
import "antd/dist/antd.css";
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
// import Session from "./../../utils/session";
import SERVICES from "../../services/apicalls";
import "./style.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Notification from "../../components/notification/index";
import Layout from "../layout";
const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const mobilePattern = /^\d{10}$/;

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="serchDesi">
          <FormControl
            className="searchBox"
            autoFocus
            placeholder="Search"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoComplete={"off"}
          />
          <img
            src="../serchIcon.svg"
            width={20}
            height={20}
            className={"serchImg"}
            alt="serchIcon"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toLowerCase().startsWith(value.toLowerCase())
          ).length > 0 ? (
            React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children
                  .toLowerCase()
                  .startsWith(value.toLowerCase())
            )
          ) : (
            <div className="noResult">No Results Found</div>
          )}
        </div>
      </div>
    );
  }
);
class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdown: false,
      stateData: StateData,
      loader: false,
      extra: 0,
      stateName: "",
      user: {
        customer_name: "",
        email: "",
        mobile: "",
        coupon_code: "",
        address1: "",
        address2: "",
        address3: "",
        landmark: "",
        pincode: "",
        locality: "",
        city: "",
        state: "Select State/Union Territories",
        charLimit: 90,
      },
      errors: {
        name: "",
        mobile: "",
        email: "",
        coupon_code: "",
        address1: "",
        address2: "",
        address3: "",
        pincode: "",
        locality: "",
        city: "",
        state: "",
      },
      formIsValid: true,
      coupon_err_msg: "",
      couponVerified: false,
      coupon_succ_msg: "",
    };
  }
  onInputChanges(field, e) {
    const { user, errors } = this.state;
    let value = e.target.value;
    if (field === "mobile" && value) {
      let numberReg = /^[0-9]+$/;
      if (value.toString().length > 10) {
        return false;
      }
      if (!numberReg.test(value)) {
        // errors.mobile = "Please enter a valid mobile number."
        return false;
      }
      if (
        value === "" ||
        value.toString().length !== 10 ||
        !mobilePattern.test(value)
      ) {
        this.setState({
          formIsValid: false,
          errors: { ...errors, mobile: "Please enter a valid mobile number." },
        });
      } else {
        this.setState({ errors: { ...errors, mobile: "" } });
      }
    }
    if (field === "customer_name") {
      // let nameReg=/^[a-zA-Z ]+$/
      // if(!nameReg.test(e.target.value)){
      //   return false;
      // }
      if (value === "" || !value.match(/^[a-zA-Z ]+$/)) {
        this.setState({
          formIsValid: false,
          errors: { ...errors, name: "Please enter a valid name." },
        });
      } else {
        this.setState({ errors: { ...errors, name: "" } });
      }
    }
    if (field === "email") {
      if (value === "" || !value.match(emailPattern)) {
        this.setState({
          formIsValid: false,
          errors: { ...errors, email: "Please enter a valid email." },
        });
      } else {
        this.setState({ errors: { ...errors, email: "" } });
      }
    }
    if (field === "address1") {
      if (value === "") {
        this.setState({
          formIsValid: false,
          errors: {
            ...errors,
            address1: "Please enter a valid address.",
          },
        });
      } else if (value.toString().length > 90) {
        this.setState({
          formIsValid: false,
          errors: {
            ...errors,
            address1: "90 characters maximum.",
          },
        });
        return false;
      } else {
        this.setState({ errors: { ...errors, address1: "" } });
      }
    }
    // if (field === "address2") {
    //   if (value === "") {
    //     this.setState({
    //       formIsValid: false,
    //       errors: {
    //         ...errors,
    //         address2: "Please enter a valid address line 2.",
    //       },
    //     });
    //   } else if (value.toString().length > 30) {
    //     this.setState({
    //       formIsValid: false,
    //       errors: {
    //         ...errors,
    //         address2: "30 characters maximum, please use the next field.",
    //       },
    //     });
    //     return false;
    //   } else {
    //     this.setState({ errors: { ...errors, address2: "" } });
    //   }
    // }
    // if (field === "address3") {
    //   if (value.toString().length > 30) {
    //     this.setState({
    //       formIsValid: false,
    //       errors: {
    //         ...errors,
    //         address3: "30 characters maximum, please use the next field.",
    //       },
    //     });
    //     return false;
    //   } else {
    //     this.setState({ errors: { ...errors, address3: "" } });
    //   }
    // }
    if (field === "pincode") {
      if (
        value === "" ||
        !value.match(/^[0-9]+$/) ||
        value.toString().length < 6
      ) {
        this.setState({
          formIsValid: false,
          errors: { ...errors, pincode: "Please enter a valid pincode." },
        });
      } else {
        this.setState({ errors: { ...errors, pincode: "" } });
      }
    }
    if (field === "locality") {
      if (value === "") {
        this.setState({ [errors.locality]: "Please enter a valid locality." });
      } else {
        this.setState({ errors: { ...errors, locality: "" } });
      }
    }
    if (field === "city") {
      if (value === "") {
        this.setState({ [errors.city]: "Please enter a valid city." });
      } else {
        this.setState({ errors: { ...errors, city: "" } });
      }
    }
    if (field === "state") {
      if (value === "" || user.state === "Select State/Union Territories") {
        this.setState({
          [errors.state]: "Please select state/union territorie.",
        });
      } else {
        this.setState({ errors: { ...errors, state: "" } });
      }
    }
    if (field === "coupon_code") {
      if (value === "") {
        this.setState({
          coupon_err_msg: "Please enter a valid redemption code.",
        });
      } else {
        this.setState({ coupon_err_msg: "" });
      }
    }
    if (field === "coupon_code") {
      this.setState({
        user: { ...user, [field]: e.target.value.replace(/\s/g, "") },
      });
    } else {
      this.setState({
        user: { ...user, [field]: e.target.value },
      });
    }
  }

  async submit(e) {
    e.preventDefault();
    let { user, couponVerified } = this.state;
    // if (!couponVerified) {
    //   message.error("Please apply coupon to proceed");
    //   return false;
    // }
    if (this.handleValidations()) {
      this.setState({ loader: true });
      try {
        console.log("abcd", user);

        let placeOrder = await SERVICES._placeOrder(user);
        console.log("abcd", placeOrder);

        if (placeOrder.result.status) {
          this.setState({
            loader: false,
          });
          this.props.history.push("/orderPlaced");
        } else {
          message.error(placeOrder.result.message);
          this.setState({
            loader: false,
          });
        }
      } catch (error) {
        console.log("error", error);
        this.setState({ loader: false });
        message.error("some error occured while calling api");
      }
    } else {
      return false;
    }
  }

  async verifyCoupon(e) {
    e.preventDefault();
    let { user } = this.state;
    if (!user.coupon_code) {
      this.setState({
        coupon_succ_msg: "",
        couponVerified: false,
        coupon_err_msg: "Please enter a valid redemption code.",
      });
      return false;
    }
    this.setState({ loader: true });
    try {
      let applyCoupon = await SERVICES._verifyCoupon({
        coupon_code: user.coupon_code,
      });
      if (applyCoupon.result.status) {
        this.setState({
          coupon_succ_msg: "Redemption code applied.",
          couponVerified: true,
          coupon_err_msg: "",
          loader: false,
        });
      } else {
        this.setState({
          coupon_succ_msg: "",
          couponVerified: false,
          coupon_err_msg: applyCoupon.result.message,
          loader: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ loader: false });
      message.error("some error occured while calling api");
    }
    console.log(user.coupon_code);
  }

  async removeCoupon(e) {
    e.preventDefault();
    const { user } = this.state;
    this.setState({
      coupon_succ_msg: "",
      couponVerified: false,
      coupon_err_msg: "",
      user: { ...user, coupon_code: "" },
    });
  }

  handleValidations() {
    console.log("error");

    let { user, couponVerified } = this.state;
    let formIsValid = true;
    let errors = {};
    let coupon_err_msg = "";
    if (
      user.customer_name === "" ||
      !user.customer_name.match(/^[a-zA-Z ]+$/)
    ) {
      formIsValid = false;
      errors.name = "Please enter a valid name.";
    }
    if (
      user.mobile === "" ||
      user.mobile.toString().length !== 10 ||
      !mobilePattern.test(user.mobile)
    ) {
      formIsValid = false;
      errors.mobile = "Please enter a valid mobile number.";
    }
    if (user.email === "" || !emailPattern.test(user.email)) {
      formIsValid = false;
      errors.email = "Please enter a valid email.";
    }
    if (user.city === "") {
      formIsValid = false;
      errors.city = "Please enter a valid city.";
    }
    if (user.coupon_code === "") {
      formIsValid = false;
      coupon_err_msg = "Please enter a valid redemption code.";
    }
    if (user.address1 === "") {
      formIsValid = false;
      errors.address1 = "Please enter a valid address.";
    }
    // if (user.address2 === "") {
    //   formIsValid = false;
    //   errors.address2 = "Please enter a valid address line 2.";
    // }
    if (user.locality === "") {
      formIsValid = false;
      errors.locality = "Please enter a valid locality.";
    }
    if (
      user.pincode === "" ||
      !user.pincode.match(/^[0-9]+$/) ||
      user.pincode.toString().length > 6
    ) {
      formIsValid = false;
      errors.pincode = "Please enter a valid pincode.";
    }
    if (user.state === "" || user.state === "Select State/Union Territories") {
      formIsValid = false;
      errors.state = "Please select state/union territorie.";
    }

    if (user.address1 && user.address1.toString().length > 90) {
      formIsValid = false;
      errors.address1 = "30 characters maximum, please use the next field.";
    }
    // if (user.address2 && user.address2.toString().length > 30) {
    //   formIsValid = false;
    //   errors.address2 = "30 characters maximum, please use the next field.";
    // }
    // if (user.address3 && user.address3.toString().length > 30) {
    //   formIsValid = false;
    //   errors.address3 = "Please enter less than 30 characters or leave empty.";
    // }

    if (formIsValid && !couponVerified) {
      formIsValid = false;
      message.error("Please apply redemption code to proceed.");
    }
    this.setState({
      errors: errors,
      coupon_err_msg: coupon_err_msg,
      formIsValid: formIsValid,
    });
    return formIsValid;
  }

  render() {
    const {
      user,
      loader,
      coupon_err_msg,
      coupon_succ_msg,
      couponVerified,
      errors,
      formIsValid,
    } = this.state;
    return (
      <div className='text-center'>
        <h1 className="claim-heading text-position">
            Sorry, the window to claim this gift is now closed.
        </h1>
      </div>
      // <div>
      //   <Notification />
      //   <Layout allProps={this.props}></Layout>
      //   <Loader
      //     className="spinner"
      //     type="TailSpin"
      //     color="#00BFFF"
      //     height={100}
      //     width={100}
      //     visible={loader}
      //   />
      //   <div className="m-a text-center mb-3">
      //     <h1 className="claim-heading mb-5">
      //       Claim your gift & join us in celebrating this festive season!
      //     </h1>
      //   </div>
      //   <div className="login-wrapper">
      //     <div className="login-form-container">
      //       <form
      //         autoComplete="new-password"
      //         ref={(el) => (this.myFormRef = el)}
      //         onSubmit={this.submit.bind(this)}
      //       >
      //         <div className="form-row mt-4">
      //           <div className="col-md-6">
      //             <div className="form-group name-form-group">
      //               <label className="login-label">Name:*</label>
      //               <input
      //                 autoComplete="off"
      //                 placeholder="Name"
      //                 type="text"
      //                 className={`form-control ${
      //                   user.customer_name !== "" ? "" : ""
      //                 }`}
      //                 value={user.customer_name}
      //                 name="customer_name"
      //                 onChange={this.onInputChanges.bind(this, "customer_name")}
      //               />
      //               <span
      //                 className={
      //                   !formIsValid && errors.name !== ""
      //                     ? "val-err"
      //                     : "val-succ"
      //                 }
      //               >
      //                 {errors.name}
      //               </span>
      //             </div>
      //           </div>
      //           <div className="col-md-6">
      //             <div className="form-group">
      //               <label className="login-label">Phone Number:*</label>
      //               <input
      //                 autoComplete="off"
      //                 placeholder="Phone Number"
      //                 type="tel"
      //                 className={`form-control ${
      //                   user.mobile !== "" && mobilePattern.test(user.mobile)
      //                     ? ""
      //                     : ""
      //                 }`}
      //                 value={user.mobile}
      //                 name="mobile"
      //                 onChange={this.onInputChanges.bind(this, "mobile")}
      //               />
      //               <span
      //                 className={
      //                   !formIsValid && errors.mobile !== ""
      //                     ? "val-err"
      //                     : "val-succ"
      //                 }
      //               >
      //                 {errors.mobile}
      //               </span>
      //             </div>
      //           </div>
      //           <div className="col-md-6">
      //             <div className="form-group name-form-group">
      //               <label className="login-label">
      //                 REAL PAGE EMAIL ADDRESS:*
      //               </label>
      //               <input
      //                 autoComplete="off"
      //                 placeholder="Enter Real Page email address"
      //                 type="email"
      //                 className={`form-control ${
      //                   user.email !== "" && emailPattern.test(user.email)
      //                     ? ""
      //                     : ""
      //                 }`}
      //                 value={user.email}
      //                 name="email"
      //                 onChange={this.onInputChanges.bind(this, "email")}
      //               />
      //               <span
      //                 className={
      //                   !formIsValid && errors.email !== ""
      //                     ? "val-err"
      //                     : "val-succ"
      //                 }
      //               >
      //                 {errors.email}
      //               </span>
      //             </div>
      //           </div>
      //           <div className="form-group col-md-4">
      //             <label className="login-label">Redemption Code:*</label>
      //             <input
      //               readOnly={couponVerified}
      //               autoComplete="off"
      //               placeholder="Redemption Code"
      //               type="text"
      //               className={`form-control ${
      //                 user.coupon_code !== "" && couponVerified ? "" : ""
      //               }`}
      //               value={user.coupon_code}
      //               name="coupon_code"
      //               onChange={this.onInputChanges.bind(this, "coupon_code")}
      //             />
      //             {coupon_err_msg && coupon_err_msg !== "" && (
      //               <span className="err-msg">{coupon_err_msg}</span>
      //             )}
      //             {coupon_succ_msg && (
      //               <span className="succ-msg">{coupon_succ_msg}</span>
      //             )}
      //           </div>

      //           <div className="form-group col-md-2">
      //             {couponVerified ? (
      //               <button
      //                 onClick={(e) => this.removeCoupon(e)}
      //                 className="btn btn-block inline-btn apply-btn1"
      //               >
      //                 REMOVE
      //               </button>
      //             ) : (
      //               <button
      //                 onClick={(e) => this.verifyCoupon(e)}
      //                 className="btn btn-block inline-btn apply-btn1"
      //               >
      //                 APPLY
      //               </button>
      //             )}
      //           </div>
      //           <h4 className="ml-2 add-address-h4">ADD YOUR ADDRESS:</h4>
      //           <div className="form-group col-md-12">
      //             <label className="login-label">Address*</label>
      //             <textarea
      //               autoComplete="off"
      //               placeholder="Address (90 characters maximum)"
      //               type="textarea"
      //               className={`form-control address ${
      //                 user.address1 !== "" ? "" : ""
      //               }`}
      //               value={user.address1}
      //               name="address1"
      //               id="textArea"
      //               onChange={this.onInputChanges.bind(this, "address1")}
      //               Row={5}
      //             />
      //             <div className="charErr">
      //               {" "}
      //               {user.address1.length}/{this.state.user.charLimit}
      //             </div>
      //             <span
      //               className={
      //                 !formIsValid && errors.address1 !== ""
      //                   ? "val-err"
      //                   : "val-succ"
      //               }
      //             >
      //               {errors.address1}
      //             </span>
      //           </div>
      //           <div className="form-group col-md-12">
      //             <label className="login-label">Address line 1:*</label>
      //             <input
      //               autoComplete="off"
      //               placeholder="Address line 1 (30 characters maximum)"
      //               type="text"
      //               className={`form-control ${user.address1 !== "" ? "" : ""}`}
      //               value={user.address1}
      //               name="address1"
      //               onChange={this.onInputChanges.bind(this, "address1")}
      //             />
      //             <span
      //               className={
      //                 !formIsValid && errors.address1 !== ""
      //                   ? "val-err"
      //                   : "val-succ"
      //               }
      //             >
      //               {errors.address1}
      //             </span>
      //           </div>

      //           <div className="form-group col-md-12">
      //             <label className="login-label">Address line 2:*</label>
      //             <input
      //               autoComplete="off"
      //               placeholder="Address line 2 (30 characters maximum)"
      //               type="text"
      //               className={`form-control ${user.address2 !== "" ? "" : ""}`}
      //               value={user.address2}
      //               name="address2"
      //               onChange={this.onInputChanges.bind(this, "address2")}
      //             />
      //             <span
      //               className={
      //                 !formIsValid && errors.address2 !== ""
      //                   ? "val-err"
      //                   : "val-succ"
      //               }
      //             >
      //               {errors.address2}
      //             </span>
      //           </div>

      //           <div className="form-group col-md-12">
      //             <label className="login-label">
      //               Address line 3 (Optional):
      //             </label>
      //             <input
      //               autoComplete="off"
      //               placeholder="Address line 3 (30 characters maximum)"
      //               type="text"
      //               className={`form-control ${user.address3 !== "" ? "" : ""}`}
      //               value={user.address3}
      //               name="address3"
      //               onChange={this.onInputChanges.bind(this, "address3")}
      //             />
      //             <span
      //               className={
      //                 !formIsValid && errors.address3 !== ""
      //                   ? "val-err"
      //                   : "val-succ"
      //               }
      //             >
      //               {errors.address3}
      //             </span>
      //           </div>

      //           <div className="form-group col-md-12">
      //             <label className="login-label">Landmark (Optional):</label>
      //             <input
      //               autoComplete="off"
      //               placeholder="landmark"
      //               type="text"
      //               className={`form-control ${user.landmark !== "" ? "" : ""}`}
      //               value={user.landmark}
      //               name="landmark"
      //               onChange={this.onInputChanges.bind(this, "landmark")}
      //             />
      //           </div>

      //           <div className="col-md-6">
      //             <div className="form-group name-form-group">
      //               <label className="login-label">Pincode:*</label>
      //               <input
      //                 autoComplete="off"
      //                 placeholder="Pincode"
      //                 type="text"
      //                 className={`form-control ${
      //                   user.pincode !== "" ? "" : ""
      //                 }`}
      //                 value={user.pincode}
      //                 name="pincode"
      //                 onChange={this.onInputChanges.bind(this, "pincode")}
      //               />
      //               <span
      //                 className={
      //                   !formIsValid && errors.pincode !== ""
      //                     ? "val-err"
      //                     : "val-succ"
      //                 }
      //               >
      //                 {errors.pincode}
      //               </span>
      //             </div>
      //           </div>
      //           <div className="form-group col-md-6">
      //             <label className="login-label">locality:*</label>
      //             <input
      //               autoComplete="off"
      //               placeholder="Locality"
      //               type="text"
      //               className={`form-control ${user.locality !== "" ? "" : ""}`}
      //               value={user.locality}
      //               name="locality"
      //               onChange={this.onInputChanges.bind(this, "locality")}
      //             />
      //             <span
      //               className={
      //                 !formIsValid && errors.locality !== ""
      //                   ? "val-err"
      //                   : "val-succ"
      //               }
      //             >
      //               {errors.locality}
      //             </span>
      //           </div>

      //           <div className="col-md-6">
      //             <div className="form-group name-form-group">
      //               <label className="login-label">City/Town:*</label>
      //               <input
      //                 autoComplete="off"
      //                 placeholder="City/Town"
      //                 type="text"
      //                 className={`form-control ${user.city !== "" ? "" : ""}`}
      //                 value={user.city}
      //                 name="city"
      //                 onChange={this.onInputChanges.bind(this, "city")}
      //               />
      //               <span
      //                 className={
      //                   !formIsValid && errors.city !== ""
      //                     ? "val-err"
      //                     : "val-succ"
      //                 }
      //               >
      //                 {errors.city}
      //               </span>
      //             </div>
      //           </div>
      //           <div className="form-group col-md-6">
      //             <label className="login-label">State/Union Territory:*</label>
      //             <input
      //               autoComplete="off"
      //               placeholder="State"
      //               type="text"
      //               className={`form-control ${user.state !== "" ? "" : ""}`}
      //               name="state"
      //               value={user.state}
      //               onChange={this.onInputChanges.bind(this, "state")}
      //             />
      //             <div className="custom-select-box dropM">
      //               <DropdownButton
      //                 onClick={(e) => {
      //                   this.setState({ isDropdown: !this.state.isDropdown });
      //                 }}
      //                 onMouseLeave={() => {
      //                   this.setState({ isDropdown: false });
      //                 }}
      //                 menuAlign="left"
      //                 show={this.state.isDropdown}
      //                 title={
      //                   <div className="labelDrop">
      //                     <div>{user.state}</div>
      //                     <div>
      //                       <img src="../public/drop-down.png" alt="arrow" />
      //                     </div>
      //                   </div>
      //                 }
      //               >
      //                 {this.state.stateData.map((i) => {
      //                   return (
      //                     <Dropdown.Item
      //                       active={user.state == i.name}
      //                       onClick={() => {
      //                         this.setState({
      //                           errors: { ...errors, state: "" },
      //                         });
      //                         this.setState({
      //                           user: { ...user, state: i.name },
      //                         });
      //                       }}
      //                       className="countryCardF"
      //                     >
      //                       <div>
      //                         <label className="nameDesign">{i.name}</label>
      //                       </div>
      //                     </Dropdown.Item>
      //                   );
      //                 })}
      //               </DropdownButton>
      //               <Dropdown>
      //                 <Dropdown.Toggle>
      //                   {
      //                     <div className="labelDrop">
      //                       <div
      //                         style={{ color: " #495057", textAlign: "left" }}
      //                       >
      //                         {user.state}
      //                       </div>
      //                       <div>
      //                         <img src="../drop-down.png" alt="arrow" />
      //                       </div>
      //                     </div>
      //                   }
      //                 </Dropdown.Toggle>

      //                 <Dropdown.Menu
      //                   as={CustomMenu}
      //                   onSelect={(e) => console.log("object", e)}
      //                 >
      //                   {this.state.stateData.map((i) => (
      //                     <Dropdown.Item
      //                       active={user.state == i.name}
      //                       onClick={() => {
      //                         console.log("HELLO", i.name);
      //                         this.setState({
      //                           errors: { ...errors, state: "" },
      //                         });
      //                         this.setState({
      //                           user: { ...user, state: i.name },
      //                         });
      //                       }}
      //                       className="countryCardF nameDesign"
      //                     >
      //                       {i.name}
      //                     </Dropdown.Item>
      //                   ))}
      //                   {this.state.stateData.map((i) => (
      //                     <Dropdown.Item
      //                       active={user.state == i.name}
      //                       onClick={() => {
      //                         console.log("HELLO", i.name);
      //                         this.setState({
      //                           errors: { ...errors, state: "" },
      //                         });
      //                         this.setState({
      //                           user: { ...user, state: i.name },
      //                         });
      //                       }}
      //                       className="countryCardF"
      //                     >
      //                       <div>
      //                         <label className="nameDesign">{i.name}</label>
      //                       </div>
      //                     </Dropdown.Item>
      //                   ))}
      //                 </Dropdown.Menu>
      //               </Dropdown>
      //             </div>

      //             <span
      //               className={
      //                 !formIsValid && errors.state !== ""
      //                   ? "val-err"
      //                   : "val-succ"
      //               }
      //             >
      //               {errors.state}
      //             </span>
      //           </div>
      //           <div className="form-group col-md-12">
      //             <label className="login-label">Landmark (Optional):</label>
      //             <input
      //               autoComplete="off"
      //               placeholder="landmark"
      //               type="text"
      //               className={`form-control ${user.landmark !== "" ? "" : ""}`}
      //               value={user.landmark}
      //               name="landmark"
      //               onChange={this.onInputChanges.bind(this, "landmark")}
      //             />
      //           </div>
      //         </div>
      //         <div className="row mt-4">
      //           <button
      //             type="submit"
      //             className="btn btn-primary btn-block btn-clr apply-btn submit-btn"
      //           >
      //             SUBMIT
      //           </button>
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      //   <div className="support-team-div">
      //     <p className="support-team-p">
      //       “If you have any issues please reach out to our{" "}
      //       <a
      //         href="https://almondhouse.com/contactus"
      //         className="support-team-a"
      //       >
      //         support team
      //       </a>{" "}
      //       for help or call us at{" "}
      //       <a href="tel:+91 9100005444" className="bottom-phone-a">
      //         9100005444
      //       </a>
      //       . Our hours are 10:00 am - 7:00 pm.”
      //     </p>
      //   </div>
      // </div>
    );
  }
}

export default withRouter(Page);
