import React from "react";
import "./style.css";

class Page extends React.Component {
  render() {
    return <h1 className="h1-main">404 page not found</h1>;
  }
}

export default Page;
