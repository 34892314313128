import React from "react";
import Routes from "./../../routes";
class Shell extends React.Component {
  render() {
    return (
        <div className="wrapper">         
          <div className="body">
            <Routes {...this.props} />
          </div>
        </div>
    );
  }
}

export default Shell;
