// import React from 'react'
// import "./style.css";
// class Page extends React.Component{

// componentDidMount(){

// }
//     render() {
//         return (
//             <div>
//                 <div className="text-center mt-5 mb-5">
//                     <img className="cur-ptr" onClick={() => this.props.allProps.history.push('/')} src="https://almondhouse.com/_next/static/images/almond-logo-d116473351a6833955b568dda837899d.svg" alt="Almondhouse"></img>
//                 </div>
//                 <div className="text-center mt-5 mb-5">
//                     <img width="20%" src="/opentext-logo-dark.svg" alt="Almondhouse"></img>
//                 </div>
//             </div>
//         )
//     }
// }
// export default Page

import React, { Component } from "react";

import { Row, Container, Col } from "react-bootstrap";
import SERVICES from "../../services/apicalls";
// import "./style.css";
import "./orders.scss";
import Layout from "../layout";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: "",
      order_id: this.props.match.params.order_id,
      skeleton_load: true,
    };
  }

  async componentDidMount() {
    console.log(this.props.match.params.order_id);
    let order_id = this.props.match.params.order_id;
    try {
      let orderDetails = await SERVICES._orderDetails({ order_id: order_id });
      console.log(orderDetails);

      if (orderDetails.result.status) {
        orderDetails = orderDetails.result.orderdetails;
        console.log(orderDetails);
        if (
          orderDetails &&
          orderDetails.status !== "delivered" &&
          orderDetails.waybill_number
        ) {
          let trackOrder = await SERVICES._blueDartTracking(
            `${orderDetails.waybill_number}`
          );
          trackOrder = JSON.parse(JSON.stringify(trackOrder));
          if (trackOrder.Status) {
            if (
              trackOrder.data &&
              trackOrder.data.Shipment &&
              trackOrder.data.Shipment.Status === "SHIPMENT DELIVERED"
            ) {
              let changeStatus = await SERVICES._changeStatus({
                order_id: orderDetails.id,
              });
              console.log(changeStatus, "changeStatus");
              if (changeStatus.result.status) {
                orderDetails["status_name"] = "delivered";
              }
            }
          }
        }
        this.setState({
          orderDetails: orderDetails,
          skeleton_load: false,
        });
      } else {
        console.log("else");
        this.checkValues(order_id);
      }
    } catch (error) {
      console.log(error, "error");

      this.checkValues(order_id);
    }
  }

  checkValues = (order_id) => {
    SERVICES._orderDetails({ order_id: order_id })
      .then((resp) => {
        if (resp.result.status) {
          this.setState({
            orderDetails: resp.result.orderdetails,
            skeleton_load: false,
          });
        } else {
          this.setState({
            orderDetails: null,
            skeleton_load: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({
          orderDetails: null,
          skeleton_load: false,
        });
      });
  };

  render() {
    let { orderDetails, order_id, skeleton_load } = this.state;
    return (
      <div>
        <Layout allProps={this.props}></Layout>
        <Container>
          {skeleton_load ? (
            <section className="orders-sec mt-5">
              <SkeletonTheme color="#ffff">
                <p>
                  <Skeleton count={5} />
                </p>
              </SkeletonTheme>
            </section>
          ) : (
            <section className="orders-sec mt-5 mb-5">
              {orderDetails ? (
                <Row>
                  <Col>
                    <div className="ur-orders">Track order</div>
                    <div className="or-row">
                      <div className="row-box mb-3 mt-3">
                        <Row>
                          <Col md={8}>
                            <div>
                              <div className="order-id">
                                Order id: {orderDetails.id}
                              </div>
                              <div className="order-count mt-2">
                                Order placed on:{" "}
                                <strong>
                                  {new Date(
                                    orderDetails.created_at
                                  ).toDateString()}
                                </strong>
                              </div>

                              <div className="order-count mt-2">
                                Phone no: <strong>{orderDetails.mobile}</strong>
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="custom-add text-right">
                              <span
                                variant="primary"
                                className="text-uppercase"
                              >
                                {orderDetails.status_name ===
                                "preparing for shipment"
                                  ? "processing"
                                  : orderDetails.status_name}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="border-boxd mt-5">
                      <div className="order-id">
                        <strong>ORDER STATUS</strong>
                      </div>
                      {orderDetails.status_name.toLowerCase() !==
                      "cancelled" ? (
                        <Row className="mt-4">
                          <div className="col-md-1 col-2 pr-0">
                            {orderDetails.status_name.toLowerCase() ===
                              "placed" ||
                            orderDetails.status_name.toLowerCase() ===
                              "preparing for shipment" ||
                            orderDetails.status_name.toLowerCase() ===
                              "shipped" ||
                            orderDetails.status_name.toLowerCase() ===
                              "delivered" ? (
                              <div className="step-box ml-auto mt-1">1</div>
                            ) : (
                              <div className="step-box1 ml-auto">1</div>
                            )}
                            {orderDetails.status_name.toLowerCase() ===
                              "shipped" ||
                            orderDetails.status_name.toLowerCase() ===
                              "delivered" ? (
                              <div className="line-fhg"></div>
                            ) : (
                              <div className="line-fhg1"></div>
                            )}
                            {orderDetails.status_name.toLowerCase() ===
                              "shipped" ||
                            orderDetails.status_name.toLowerCase() ===
                              "delivered" ? (
                              <div className="step-box ml-auto">2</div>
                            ) : (
                              <div className="step-box1 ml-auto">2</div>
                            )}

                            {orderDetails.status_name.toLowerCase() ===
                            "delivered" ? (
                              <div className="line-fhg"></div>
                            ) : (
                              <div className="line-fhg1"></div>
                            )}
                            {orderDetails.status_name.toLowerCase() ===
                            "delivered" ? (
                              <div className="step-box ml-auto">3</div>
                            ) : (
                              <div className="step-box1 ml-auto">3</div>
                            )}
                          </div>
                          <div className="col-md-11 col-10">
                            {/* <div className="order-dname"><strong>order placed</strong></div>
                                            <p className="or-Name-sd">We have received your order</p> */}

                            <div className="order-dname">
                              <strong>processing</strong>
                            </div>
                            <p className="or-Name-sd">
                              Our craftsmen are preparing your order.
                            </p>

                            <div className="order-dname mt-5 pt-4">
                              <strong>shipped</strong>
                            </div>
                            <div className="or-Name-sd">
                              Your order is on its way .
                            </div>
                            {(orderDetails.status_name === "shipped" ||
                              orderDetails.status_name === "delivered") &&
                              orderDetails.waybill_number && (
                                <div
                                  className="track-details float-left"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/TrackOrder/${order_id}`
                                    )
                                  }
                                >
                                  Tracking Details
                                </div>
                              )}

                            <div className="order-dname mt-5 pt-4">
                              <strong>delivered</strong>
                            </div>
                            <p className="or-Name-sd">
                              May you relish the flavours of purity!.
                            </p>
                          </div>
                        </Row>
                      ) : orderDetails.status_name.toLowerCase() !==
                        "cancelled" ? (
                        <Row className="mt-4">
                          <Col md={1} className="pr-0">
                            {orderDetails.status_name === "placed" ? (
                              <div className="step-box ml-auto mt-1">1</div>
                            ) : (
                              <div className="step-box1 ml-auto">1</div>
                            )}
                            {orderDetails.status_name ===
                            "preparing for shipment" ? (
                              <div className="line-fhg"></div>
                            ) : (
                              <div className="line-fhg1"></div>
                            )}
                            {orderDetails.status_name ===
                            "preparing for shipment" ? (
                              <div className="step-box ml-auto">2</div>
                            ) : (
                              <div className="step-box1 ml-auto">2</div>
                            )}
                            {orderDetails.status_name === "delivered" ? (
                              <div className="line-fhg"></div>
                            ) : (
                              <div className="line-fhg1"></div>
                            )}
                            {orderDetails.status_name === "delivered" ? (
                              <div className="step-box ml-auto">3</div>
                            ) : (
                              <div className="step-box1 ml-auto">3</div>
                            )}
                          </Col>
                          <Col>
                            <div className="order-dname">
                              <strong>order placed</strong>
                            </div>
                            <p className="or-Name-sd">
                              We have received your order
                            </p>

                            <div className="order-dname mt-5 pt-4">
                              <strong>order in process</strong>
                            </div>
                            <p className="or-Name-sd">
                              Our craftsmen are preparing your order.
                            </p>

                            <div className="order-dname mt-5 pt-4">
                              <strong>delivered</strong>
                            </div>
                            <p className="or-Name-sd">
                              May you relish the flavours of purity!.
                            </p>
                          </Col>
                        </Row>
                      ) : (
                        <Row className="mt-4">
                          <Col md={1} sm={2} xs={2} className="pr-0">
                            <div className="step-box ml-auto mt-1">1 </div>
                            <div className="line-fhg"></div>
                            <div className="step-box ml-auto">2</div>
                          </Col>
                          <Col>
                            <div className="order-dname">
                              <strong>order placed</strong>
                            </div>
                            <p className="or-Name-sd pb-2">
                              We have received your order
                            </p>

                            <div className="order-dname mt-5 pt-4">
                              <strong>Cancelled</strong>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <div className="row-box mt-5 mb-5">
                      <div className="order-id">
                        <strong>Shipping Address</strong>
                      </div>
                      <div className="order-count mt-2 tex-vnb">
                        {orderDetails.address1},
                        {orderDetails.address2 && orderDetails.address2 + ","}
                        {orderDetails.address3 &&
                          orderDetails.address3 + ","}{" "}
                        {orderDetails.landmark}{" "}
                      </div>
                      <div className="order-count mt-2 tex-vnb">
                        {`${orderDetails.locality},${orderDetails.city}, ${orderDetails.state} - ${orderDetails.pincode}.`}{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div className="text-center">
                  <div className="mb-3">
                    <img src="/web-error.svg" alt="Almondhouse"></img>
                  </div>
                  <h1>No order found</h1>
                </div>
              )}
            </section>
          )}
        </Container>
      </div>
    );
  }
}
