import React from "react";

import "./style.css";
import Layout from "../layout";

export default function Page(props) {
  return (
    <div className="wraper">
      <Layout allProps={props}></Layout>
      <div className="text-center">
        <div className="mb-3">
          <img src="/smily.png" alt="Almondhouse" className="success-img"></img>
        </div>
        <h1 className="congrats-h1">Congratulations!</h1>
        <h1 className="congrats-h1">Your gift is claimed successfully.</h1>
        <span className="note">
          Note: You will receive an email to track the status of your shipment.
        </span>
      </div>
    </div>
  );
}
