import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TrackStatus from "../screens/trackStatus";
import LoginPage from "../screens/Login";
import PageNotFound from "./../screens/404";
import PlacedOrderPage from "../screens/orderPlaced";
import TrackOrder from "../screens/trackOrder";

class MainRoute extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <Route path="/couponRedemption" exact component={LoginPage} />
            <Route path="/orderPlaced" exact component={PlacedOrderPage} />
            <Route
              path="/TrackStatus/:order_id"
              exact
              component={TrackStatus}
            />
            <Route path="/trackOrder/:order_id" exact component={TrackOrder} />
            <Route path="*" exact component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default MainRoute;
