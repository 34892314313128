import React from "react";
import "./styles.scss";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
const tagManagerArgs = {
  gtmId: "GTM-NRHLKP7",
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize("UA-82380022-51");
ReactGA.pageview(window.location.pathname + window.location.search);
class Page extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2079895,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
        <div className="logo-img-div">
          <img
            className="cur-ptr"
            onClick={() => this.props.allProps.history.push("/")}
            src="https://almondhouse.com/static/images/home/almond-logo.svg"
            alt="Almondhouse"
          ></img>
        </div>
        <div className="open-text-img-div">
          <img
            className="open-text-img"
            src="/rp-logo-2022.svg"
            alt="Almondhouse"
          ></img>
        </div>
      </div>
    );
  }
}
export default Page;
