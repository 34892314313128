import fetch from "isomorphic-unfetch";

function _post(url, headers, data) {
  const options = {
    method: "POST",
    headers: headers.headers,
    body: JSON.stringify(data),
  };
  return fetch(url, options).then((r) => {
    return r.json();
  });
}

function _get(url, headers, data) {
  const options = {
    method: "GET",
    headers: headers,
  };
  return fetch(url, options).then((r) => {
    return r.json();
  });
}

function _put(url, headers, data) {
  const options = {
    method: "PUT",
    headers: headers.headers,
    body: JSON.stringify(data),
  };
  return fetch(url, options).then((r) => {
    return r.json();
  });
}

function _do_call(type, url, headers, data) {
  console.log(type, url, headers, data);

  switch (type) {
    case "POST":
      return _post(url, headers, data);
    case "GET":
      return _get(url, headers, data);
    case "PUT":
      return _put(url, headers, data);
    default:
      return _get(url, headers, data);
  }
}

function _api_calls(type, url, headers = {}, data = {}) {
  console.log(headers, data);

  let proxy = `https://api.almondhouse.com/api`;
  url = proxy + url;
  switch (type) {
    case "POST":
      return _post(url, headers, data);
    case "GET":
      return _get(url, headers);
    case "PUT":
      break;
    default:
      return _get(url, headers);
  }
}

const HttpCalls = { _do_call, _post, _get, _api_calls };

export default HttpCalls;
