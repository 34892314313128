import React, { Component } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import './style.css'
export default class index extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "ScannedLocation",
                text: "Location",
                className: "visit-id",
                align: "left",
                sortable: false,
            },
            {
                key: "Scan",
                text: "Details",
                className: "common-name",
                align: "left",
                sortable: false
            },
            {
                key: "ScanDate",
                text: "Date",
                className: "common-name",
                sortable: false
            },
            {
                key: "ScanTime",
                text: "Time",
                className: "common-name",
                align: "left",
                sortable: false,
            },
        ];
        this.config = {
            page_size: 15,
            length_menu: [],
            button: {
                excel: false,
                print: false,
                extra: false
            },
            show_length_menu: false,
            show_last: false,
            show_first: false,
            pagination: 'advance',
            show_filter : false,
            show_info : false,
            show_pagination : false
        }

        this.state = {
            records: this.props.sessions,
        }
    }

    
    render() {
        return (
            <div className="customized-data-table">
                <ReactDatatable
                    config={this.config}
                    records={this.props.sessions}
                    columns={this.columns}
                />
                <style jsx global>
                    {`
                        tr{
                            background-color: white
                        }
                    `}
                </style>
            </div>
        )
    }
}
