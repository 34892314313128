import HttpCalls from "./servicecaller";

import fetch from "isomorphic-unfetch";

function _get(url, headers) {
  const options = { method: "GET", headers: headers.headers };
  return fetch(url, options).then((r) => {
    return r.json();
  });
}

const _verifyCoupon = async (data) => {
  data = getData(data);
  console.log(data, "data");
  let { _api_calls } = HttpCalls;
  let headers = await headersData();
  return _api_calls(
    "POST",
    "/realpage_master_coupons/realpageapplycoupon",
    headers,
    data
  );
};

const _placeOrder = async (data) => {
  data = getData(data);
  let { _api_calls } = HttpCalls;
  let headers = await headersData();
  return _api_calls("POST", "/realpage_coupon_orders/realpagecouponorderplaced", headers, data);
};

const _orderDetails = async (data) => {
  data = getData(data);
  let { _api_calls } = HttpCalls;
  let headers = await headersData();
  return _api_calls("POST", "/realpage_coupon_orders/realpagecouponorderdetails", headers, data);
};

const _changeStatus = async (data) => {
  data = getData(data);
  let { _api_calls } = HttpCalls;
  let headers = await headersData();
  return _api_calls(
    "POST",
    "/realpage_coupon_orders/realpagechangeDeliveryStatus",
    headers,
    data
  );
};

export const _blueDartTracking = async (trackingNumber) => {
  let proxy = `https://cors-anywhere.herokuapp.com/`;
  let url =
    proxy +
    `https://bluedartapi.axlr.info/trackOrder.php?trackingNumber=${trackingNumber}`;
  let headers = await headersData();
  return _get(url, headers);
};

const headersData = async (cookies) => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

const getData = (data) => {
  return {
    ...data,
  };
};

const apiMethods = {
  _verifyCoupon,
  _placeOrder,
  _orderDetails,
  _blueDartTracking,
  _changeStatus,
};
export default apiMethods;
